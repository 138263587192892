import React, { useEffect, useState } from "react";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
function AddCategory() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const getCategory = () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage?.getItem('token')}`
      );

   

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        "https://newapi.jaishreeramhealthcareprivateltd.com/admin/Category",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => setData(result.data))
       
    } catch (error) {
      console.error("Error fetching Category", error);
    }
  };

  const deleteUser = async (id) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage?.getItem('token')}`);
      
      const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow"
      };
      
      fetch(`https://newapi.jaishreeramhealthcareprivateltd.com/admin/category/${id}`, requestOptions)
        .then((response) => {return response.json()})
        .then((result) => {
          if(result.success===true){
            console.log("Delete Result:", result);
            getCategory();
          }
          else{
            console.log("Error deleting data");
          }
        })
        
    } catch (error) {
      console.log('Error deleting data', error);
      
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <>
      <div style={{ height: "88vh", overflowY: "scroll" }}>
        <div className="container1" style={{ marginBottom: "240px" }}>
          <div className="row mt-4 p-0 m-0">
            <div className="col-lg-12 col-sm-12 col-md-12 p-0">
              <div
                className="card position-relative"
                style={{ border: "1", color: "white", width: "100%" }}
              >
                <div
                  className="card-header d-flex justify-content-between position-sticky top-0 text-white"
                  style={{ backgroundColor: "#ff1983" }}
                >
                  <h6 style={{ fontSize: 21 }}>ALL Category </h6>
                  <h6
                    onClick={() => navigate("/AllCatergery")}
                    style={{ fontSize: 21, cursor: "pointer" }}
                  >
                    Add+
                  </h6>
                </div>
                <div className="card-body p-0 mt-2">
                  <div style={{ overflowX: "auto", overflowY: "auto" }}>
                    <table
                      className="table"
                      style={{ border: "1", color: "black" }}
                    >
                      <tr className="ps-5 " style={{ border: "1" }}>

                        <th className="ps-2" style={{ border: "1" }}>
                          Sr.No.
                        </th>
                        <th className="ps-2" style={{ border: "1" }}>
                          category_name
                        </th>
                        <th className="ps-2" style={{ border: "1" }}>
                          category_screen
                        </th>
                        <th className="ps-2" style={{ border: "1" }}>
                          category_image
                        </th>
                        <th className="ps-2" style={{ border: "1" }}>
                          createdAt
                        </th>
                        <th className="ps-2" style={{ border: "1" }}>
                          Del
                        </th>
                        
                      </tr>

                      {data?.map((res, index) => (
                        <tr
                          key={res._id}
                          className="ps-2"
                          style={{ border: "1" }}
                        >
                          <td className="ps-2" style={{ border: "1" }}>
                            {index + 1}
                          </td>

                          <td className="ps-2" style={{ border: "1" }}>
                            {res.category_name}
                          </td>
                          <td className="ps-2" style={{ border: "1" }}>
                            {" "}
                            {res.category_screen}{" "}
                          </td>
                          <td className="ps-2" style={{ border: "1" }}>
                            <img src={res.category_image} alt="Category" style={{width:"100px",height:"auto"}} />
                          </td>
                          <td className="ps-2" style={{ border: "1" }}>
                            {res.createdAt}
                          </td>
                          <td className="ps-2" style={{ border: "1" }}>
                            <MdDeleteOutline
                              onClick={() => {
                                deleteUser(res._id);
                              }}
                              style={{ cursor: "pointer",backgroundColor:"#ff69b4",borderRadius:"10px" }}
                              className="fs-1  text-white"
                            />
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCategory;
