import './App.css';
import { Routes, Route, NavLink, useLocation, Navigate, useNavigate } from "react-router-dom";
import { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { CiSearch } from "react-icons/ci";
import { IoIosLogOut } from "react-icons/io";
import Home from './Compontes/Home';
import Upload from './Compontes/Upload';
import Login from './Compontes/Login';
import AddSubCategoryData from './Compontes/AddSubCategoryData';
import AddCategory from './Compontes/AddCategory';
import NewCategory from './Compontes/NewCategory';
import logo from './Compontes/img/jai.png';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isFocused, setIsFocused] = useState(false);
  const isAuth = localStorage.getItem("token"); // Get authentication token from localStorage

  console.log(isAuth, 'isAuth=====>');

  // Protected Route Wrapper
  const ProtectedRoute = ({ element }) => {
    return isAuth ? element : <Navigate to="/" replace />;
  };

  // Authenticated Route Wrapper
  const AuthRoute = ({ element }) => {
    return isAuth ? <Navigate to="/Home" replace /> : element;
  };

  const handleLogoutClick = () => {
    try {
      const isAuth = localStorage.getItem("token");
  
      if (!isAuth) {
        toast.error("You are already logged out.", { autoClose: 3000 });
        return;
      }
  
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${isAuth}`);
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };
  
      fetch("https://newapi.jaishreeramhealthcareprivateltd.com/admin/logout", requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((result) => {
          if (result.success === true || result.status === "001") {
            localStorage.removeItem("token"); // Remove token from localStorage
            toast.success(result.message || "Logout successful", { autoClose: 3000 });
            navigate("/"); // Redirect to login page
          } else {
            toast.error(result.message || "Logout failed", { autoClose: 2000 });
          }
        })
        .catch((error) => {
          console.error("Fetch Error:", error);
          toast.error("Logout failed. Please try again later.", { autoClose: 3000 });
        });
    } catch (error) {
      console.error("Unexpected Error:", error);
      toast.error("An unexpected error occurred. Please try again.", { autoClose: 3000 });
    }
  };
  

  return (
    <>
      <ToastContainer />

      {/* Top Container (Conditionally Rendered) */}
      {location.pathname !== "/" && (
        <div className="container-fluid" style={{ background: "black", color: "white", top: 0 }}>
          <div className="row">
            <div className="col-lg-2 col-sm-12 col-md-6">
              <div className="p-2 logo1">
                <img
                  src={logo}
                  style={{ backgroundSize: "cover", height: "100%", width: "100%", borderRadius: "10px" }}
                  alt="Logo"
                />
              </div>
            </div>
            <div className="col-lg-8 col-sm-12 col-md-6 p-3 serachInput" style={{backgroundColor:"black"}}>
              <div className={`pb-2 ps-2 ${isFocused ? 'shadow' : ''}`} style={{ border: "1px solid white", borderRadius: "5px" }}>
                <CiSearch size={20} style={{ color: "white" }} />
                <input
                  className="ps-2"
                  placeholder="Enter your name"
                  
                  style={{ background: "none", outline: "none", border: "none", color: "white" }}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
                <style>
                  {`
                    ::placeholder { 
                      color:rgb(247, 241, 245); 
                    }
                  `}
                </style>
              </div>
            </div>
            <div className="col-lg-2 col-sm-12 col-md-6" style={{backgroundColor:"black"}}>
              {isAuth && (
                <NavLink onClick={handleLogoutClick} className="mt-3 p-2 log" style={{ background: "none", justifyContent: "flex-end", margin: 10 }}>
                  <IoIosLogOut size={25} />
                  <li style={{ textDecoration: "none", listStyle: "none", paddingLeft: 10, fontSize: 17, fontWeight: "600" }}>
                    logOut
                  </li>
                </NavLink>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Routes */}
      <Routes>
        <Route path="/" element={<AuthRoute element={<Login />} />} />
        <Route path="/Home" element={<ProtectedRoute element={<Home />} />} />
        <Route path="/Upload" element={<ProtectedRoute element={<Upload />} />} />
        <Route path="/AddSubCategoryData" element={<ProtectedRoute element={<AddSubCategoryData />} />} />
        <Route path="/AllCatergery" element={<ProtectedRoute element={<NewCategory />} />} />
        <Route path="/AddCategory" element={<ProtectedRoute element={<AddCategory />} />} />
      </Routes>
    </>
  );
}

export default App;
