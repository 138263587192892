
import React, { useState } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import Loader from "react-js-loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UploadData() {
  const [categoryType, setCategoryType] = useState("Home");
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setCategoryImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!categoryName || !categoryImage) {
      toast.error("Please fill all fields and upload an image.");
      return;
    }

    setLoading(true); // Start loading

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage?.getItem("token")}`);

    const formdata = new FormData();
    formdata.append("category_name", categoryName);
    formdata.append("category_image", categoryImage);
    formdata.append("category_screen", categoryType);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://newapi.jaishreeramhealthcareprivateltd.com/admin/categories",
        requestOptions
      );
      const result = await response.json();

      if (response.ok) {
        toast.success("Category added successfully!");
        navigate("/Upload");
      } else {
        toast.error(result.message || "Failed to add category. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div style={{ height: "88vh", overflowY: "scroll" }}>
      <ToastContainer /> {/* Toast Container */}
      <div className="admin-container h-100">
        <div className="add-category-form">
          <h3>Add a New Category</h3>
          <form onSubmit={handleSubmit}>
            <label htmlFor="categoryType">Add Category In</label>
            <select
              id="categoryType"
              value={categoryType}
              onChange={(e) => setCategoryType(e.target.value)}
            >
              <option>Home</option>
              <option>Others</option>
            </select>

            <label htmlFor="categoryName">Category Name</label>
            <input
              type="text"
              id="categoryName"
              placeholder="Enter Category Name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />

            <label htmlFor="photoUpload">Upload a photo</label>
            <input
              type="file"
              id="photoUpload"
              className="photo-upload"
              onChange={handleFileChange}
            />

            <button
              type="submit"
              className="add-button"
              disabled={loading} // Disable button while loading
            >
              {loading ? (
                <Loader type="spinner-cub"  title={"spinner-cub"} size={40} />
              ) : (
                "Add"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UploadData;

