import React, { useEffect, useState } from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import { MdDeleteOutline } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import Loader from "react-js-loader";

function AddSubCategoryForm() {
  const navigate = useNavigate();

  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setcategoryName] = useState('')
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [url, setUrl] = useState("");
  const [whatsappNo, setWhatsappNo] = useState("");
  const [file, setFile] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [data, setData] = useState([]);
  const [editId, setEditId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  console.log(editId)


  const getCategory = () => {
setLoading(true)
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage?.getItem('token')}`
      );



      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        "https://newapi.jaishreeramhealthcareprivateltd.com/admin/Category",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => setCategories(result.data)).finally(()=>{
          setLoading(false)
          })

    } catch (error) {
      console.error("Error fetching Category", error);
    }
  };

  const AddSubCategoryData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage?.getItem('token')}`
    );

    const formdata = new FormData();
    formdata.append("category_id", categoryId);
    formdata.append("category", categoryName);
    formdata.append("name", name);
    formdata.append("designation", designation);
    formdata.append("address", address);
    formdata.append("city", city);
    formdata.append("url", url);
    if (file) {
      formdata.append("subCategory_image", file);
    }
    formdata.append("whatsapp_number", whatsappNo);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
    };

    fetch(
      "https://newapi.jaishreeramhealthcareprivateltd.com/admin/createSubcategory",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          getSubCategoryData();
          resetForm();
          navigate("/AddSubCategoryData")
                  toast.success("Category added successfully!");

        } else {
          console.error("Add Error:", result.error);
                toast.error(result.error);

        }
      })
      .catch((error) => console.error("Error:", error));
  };

const updateSubcategory = () => {
  const token = localStorage?.getItem("token");
  if (!token) {
    console.error("Token is missing!");
    return;
  }

  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);

  const formdata = new FormData();
  formdata.append("category_id",categoryId); // Use consistent naming
  formdata.append("category", categoryName);
  formdata.append("name", name);
  formdata.append("designation", designation);
  formdata.append("address", address);
  formdata.append("city", city);
  formdata.append("url", url);
  if (file) {
    formdata.append("subCategory_image", file);
  }
  formdata.append("whatsapp_number", whatsappNo);

  const requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: formdata,
  };

  fetch(
    `https://newapi.jaishreeramhealthcareprivateltd.com/admin/subCategory/${editId}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      if (result.success) {
        getSubCategoryData();
        resetForm();
        setEditMode(false);
        setEditId(null);
        navigate("/AddSubCategoryData");
        toast.success("Category updated successfully!");
      } else {
        console.error("Update Error:", result.message);
        toast.error(result.error  );
      }
    })
    .catch((error) => console.error("Fetch Error:", error));
};


  const getSubCategoryData = () => {
    const myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage?.getItem('token')}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      "https://newapi.jaishreeramhealthcareprivateltd.com/admin/subCategory",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.error("Error:", error));
  };

  useEffect(() => {
    getSubCategoryData();
    getCategory();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editMode) {
      updateSubcategory();
    } else {
      AddSubCategoryData();
    }
  };

  const resetForm = () => {
    setCategoryId("");
    setName("");
    setDesignation("");
    setAddress("");
    setCity("");
    setUrl("");
    setWhatsappNo("");
    setFile(null);
  };

  const deleteSubCategory = (id) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage?.getItem('token')}`);

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(
      `https://newapi.jaishreeramhealthcareprivateltd.com/admin/subCategory/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          // After deletion, fetch updated data
          getSubCategoryData();
          console.log("Subcategory deleted successfully");
        } else {
          console.error("Error deleting subcategory:", result.message);
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <div style={{ height: "88vh", overflowY: "scroll" }}>
    <ToastContainer/>
      <div
        className="card-header d-flex justify-content-between mt-3 p-2 position-sticky top-0 rounded-3"
        style={{ backgroundColor: "#f12289", zIndex: 1 }}
      >
        <h6 style={{ color: "white", fontSize: 21, paddingLeft: "1.5rem" }}>
          Add Sub Category
        </h6>
        <h6
          onClick={() => setIsFormVisible(!isFormVisible)}
          style={{
            color: "white",
            fontSize: 21,
            cursor: "pointer",
            paddingRight: "1.5rem",
          }}
        >
          Add+
        </h6>
      </div>

      {isFormVisible ? (
        <form onSubmit={handleSubmit} className="sub-category-form">
          <div className="form-group">
            <label>Choose Category</label>
            <select
              value={categoryId}
              onChange={(e) => {
                const selectedOption = e.target.options[e.target.selectedIndex];
                setCategoryId(e.target.value); // Set the selected category ID
                setcategoryName(selectedOption.getAttribute("name"));
              }}
            >
              <option value="">Select Category</option>
              {categories.map((category) =>
                <option key={category._id} value={category._id} name={category.category_name}>{category.category_name}</option>
              )}
            </select>
          </div>

          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Category Name"
            />
          </div>

          <div className="form-group">
            <label>Designation</label>
            <input
              type="text"
              value={designation}
              onChange={(e) => setDesignation(e.target.value)}
              placeholder="Add Designation"
            />
          </div>

          <div className="form-group">
            <label>Address</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Add Address"
            />
          </div>

          <div className="form-group">
            <label>City</label>
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="Add City"
            />
          </div>

          <div className="form-group">
            <label>URL</label>
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Add website URL"
            />
          </div>

          <div className="form-group">
            <label>WhatsApp No</label>
            <input
              type="text"
              value={whatsappNo}
              onChange={(e) => setWhatsappNo(e.target.value)}
              placeholder="Add WhatsApp Number"
            />
          </div>

          <div className="form-group">
            <label>Upload Image</label>
            <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          </div>

          <button type="submit" className="submit-button">
            {editMode ? "Update" : "Add"}
          </button>
        </form>
      ) : (
        <div className="container1" style={{ marginBottom: "240px", width: "100%" }}>
          <div className="row mt-4 p-0 m-0">
            <div className="col-lg-12 col-sm-12 col-md-12 p-0">
              <div className="card" style={{ border: "none", color: "white", width: "100%" }}>
                <div className="card-body p-0">
                  <div style={{ overflowX: "auto", overflowY: "auto" }}>
                    <table className="table" style={{ border: "none", color: "black", width: "100%" }}>
                      <tr style={{ border: "none", backgroundColor: "#f12289" }}>
                        <th className="ps-2">SN</th>
                        <th className="ps-2">Category</th>
                        <th className="ps-2">Name</th>
                        <th className="ps-2">Address</th>
                        <th className="ps-2">City</th>
                        <th className="ps-2">WhatsApp No</th>
                        <th className="ps-2">Designation</th>
                        <th className="ps-2">Image</th>
                        <th className="ps-2">Del</th>
                        <th className="ps-2">Edit</th>

                      </tr>
{
  loading? <Loader type="spinner-cub"  title={"spinner-cub"} size={40} />:

  data?.map((item, index) => (
    <tr key={item._id} style={{ border: "none" }}>
      <td>{index + 1}</td>
      <td>{item.category}</td>
      <td>{item.name}</td>
      <td>{item.address}</td>
      <td>{item.city}</td>
      <td>{item.whatsapp_number}</td>

      <td>{item.designation}</td>

      <td className="ps-2" style={{ border: "1" }}>
    <img src={item.subCategory_image} alt="Category" style={{width:"100px",height:"auto"}} />
        </td>
      <td className="ps-2" style={{ border: "1" }}>
        <MdDeleteOutline
          onClick={() => deleteSubCategory(item._id)} // Call deleteSubCategory with the item's ID
          style={{
            cursor: "pointer",
            backgroundColor: "#ff69b4",
            borderRadius: "10px"
          }}
          className="fs-1 text-white"
        />
      </td>

      <td>
        <button
          onClick={() => {
            setEditId(item._id); // Set the ID of the item to be updated
            setEditMode(true); // Enable edit mode
            setIsFormVisible(true); // Show the form
            setCategoryId(item.category_id); // Populate category ID
            setcategoryName(item.category); // Populate category name
            setName(item.name); // Populate name
            setDesignation(item.designation); // Populate designation
            setAddress(item.address); // Populate address
            setCity(item.city); // Populate city
            setUrl(item.url); // Populate URL
            setWhatsappNo(item.whatsapp_number); // Populate WhatsApp number
            setFile(null); // Clear file field
          }}
          className="btn btn-warning"
        >
          Edit
        </button>

      </td>
    </tr>
  ))
}
                    

                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              ...
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Save changes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSubCategoryForm;

